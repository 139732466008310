import React from "react"
import { Link } from "gatsby"

const AboutContent = () => (

	<div className="container ng-scope">
	    <div className="row">
	        <div className="col-lg-12">
	            <h1 className="pb-2 mt-4 mb-2 border-bottom">About</h1>
	        </div>
	    </div>
	    <div className="row">
	        <div className="col-sm-12">
	            <p>World in Catalog is a place of inspiration to help visitors find 
					and enjoy the places of interest. New York City, its magical Central Park, 
					Los Angeles are available now, and more popular cities or special places will be added soon.
					We are expanding with every new place added to our menu.
				</p>
				<p>
					Our team is located in New York City. We are the visionaries,
					technical gurus and enthusiasts who are passionate about the World in Catalog project. 
				</p>
				<p>
					If you have any feedback or questions, do not hesitate to contact us - we are happy to listen.
				</p>
	        </div>
	    </div>

		{/* <div className="row">
			<div className="col-lg-12">
				<form name="sentMessage" id="contactForm" novalidate>
					<div className="row">
						<div className="col-md-6">
							<div className="form-group">
								<input type="text" className="form-control" placeholder="Your Name *" id="name" required data-validation-required-message="Please enter your name.">
								<p className="help-block text-danger"></p>
							</div>
							<div className="form-group">
								<input type="email" className="form-control" placeholder="Your Email *" id="email" required data-validation-required-message="Please enter your email address.">
								<p className="help-block text-danger"></p>
							</div>
							<div className="form-group">
								<input type="tel" className="form-control" placeholder="Your Phone *" id="phone" required data-validation-required-message="Please enter your phone number.">
								<p className="help-block text-danger"></p>
							</div>
						</div>
						<div className="col-md-6">
							<div className="form-group">
								<textarea className="form-control" placeholder="Your Message *" id="message" required data-validation-required-message="Please enter a message."></textarea>
								<p className="help-block text-danger"></p>
							</div>
						</div>
						<div className="clearfix"></div>
						<div className="col-lg-12 text-center">
							<div id="success"></div>
							<button type="submit" className="btn btn-xl">Send Message</button>
						</div>
					</div>
				</form>
			</div>
		</div> */}

		<p></p>

	</div>

)

export default AboutContent
